body {
    margin: 0; padding: 0;
}

.mobile {
    display: none;
}

@media screen and (max-width: 768px) { 
    .menu,
    .page { 
        display: none;
    }
    .mobile {
        display: block !important;
    }
}